
<template>
  <div id="notification-table-loading" class="vs-con-loading__container">
    <vx-card>
      <vs-table
      multiple class="mb-4"
      v-model="selected"
      @dblSelection="markAsRead"
      :data="pageData.data"
      >
        <template slot="header">
          <div class="md:flex py-2 md:py-4 w-full">
            <div class="sm:flex flex-grow items-center mb-2 md:mb-0">
              <div class="sm:mr-2 mr-0 mb-2 sm:mb-0">
                <vs-dropdown vs-trigger-click class="cursor-pointer">
                  <div class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                    <span v-if="pageData.total" class="mr-2">{{ paginationInfo }}</span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                  </div>
                  <vs-dropdown-menu>
                  <vs-dropdown-item @click="itemsPerPage = 10">
                      <span>10</span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="itemsPerPage = 50">
                      <span>50</span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="itemsPerPage = 100">
                      <span>100</span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="itemsPerPage = 200">
                      <span>200</span>
                  </vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </div>
              <div class="flex">
                <select v-model="filter" @change="fetchNotifications()"
                  class="form-select mr-2" style="max-width:8rem;" placeholder="--filter--">
                  <option selected value="" >--filter--</option>
                  <option value="1" >Read</option>
                  <option value="0" >Unread</option>
                  <option value="all" >All</option>
                </select>
                <vs-dropdown vs-trigger-click class="cursor-pointer mr-4">
                  <div class="p-3 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium">
                    <!-- <span class="mr-2"s>Actions</span> -->
                    <feather-icon icon="MoreVerticalIcon" svgClasses="h-4 w-4" />
                  </div>
                  <vs-dropdown-menu>
                    <vs-dropdown-item @click.prevent="deleteNotifications()">
                      <span class="flex items-center">
                        <span>Delete</span>
                      </span>
                    </vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </div>
            </div>
            <vs-input class="sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" 
            v-model="searchQuery" @input="updateSearchQuery" placeholder="Search..." />
          </div>
        </template>
        <template slot="thead">
            <vs-th>Title</vs-th>
            <vs-th>Message</vs-th>
            <vs-th>Date</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :data="tr"  :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="tr.title">
                 <span>{{tr.title}} </span> <span v-if="!tr.is_seen" class="rounded-lg px-1 inline vs-chip-success con-color bg-chip-transparent">new</span>
              </vs-td>
              <vs-td :data="tr.text">
                  {{tr.text | truncate(70)}}
              </vs-td>
              <vs-td :data="tr.created_at">
                  {{tr.created_at | date_time}}
              </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-pagination
        :total="pageData.last_page || 1"
        :max="7"
        v-model="currentPage" />
    </vx-card>
  </div>
</template>

<script>
import _ from 'lodash';

export default{
  data() {
    return {
      filter: "all",
      searchQuery: "",
      selected:[],
    }
  },
  computed:{
    pageData() {
      return this.$store.state.notification.pageData
    },
    currentPage: {
      get() {
        return this.pageData.current_page
      },
      set(val) {
        if(!this.pageData.current_page || this.pageData.current_page == val) return
        this.fetchNotifications(val)
      }
    },
    itemsPerPage: {
      get() {
        return +this.pageData.per_page || 10
      },
      set(val) {
        this.fetchNotifications(1, val)
      }
    },
    paginationInfo(){
      return `${this.currentPage * this.itemsPerPage - (this.itemsPerPage - 1)} 
      - ${this.pageData.total - this.currentPage * this.itemsPerPage > 0 
      ? this.currentPage * this.itemsPerPage : this.pageData.total}
       of ${this.pageData.total}`
    }
  },
  methods: {
    fetchNotifications(page, per_page = null) {
      let payload = {page: page || 1, per_page: per_page || this.itemsPerPage }
      if(this.filter != 'all'){ payload.is_seen = this.filter }
      if(this.searchQuery){ payload.search = this.searchQuery }
      this.$vs.loading({ container: '#notification-table-loading', color: "#444", type: "sound"})
      this.$store.dispatch("notification/fetchNotifications", payload)
      .then((response) => {
        this.$vs.loading.close('#notification-table-loading > .con-vs-loading')
      })
    },
    updateSearchQuery: _.debounce(function(string) {
      this.fetchNotifications()
    }, 2000),
    deleteNotifications(){
      if(!this.selected.length){ this.alertError("Please select an item"); return}
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Are you sure?',
        text: `You are about to delete ${this.selected.length} notification${this.selected.length>1 ? 's': ''}`,
        accept: this.acceptDelete
      })
    },
    acceptDelete(){
      let selectedIds = this.selected.map((i)=> i.id);
      const formData = new FormData()
      formData.append('data', JSON.stringify({items: selectedIds}))
      this.$http.post("/notifications/delete", formData)
      .then((response) => {
        if(response.data.success){
          this.fetchNotifications()
        }
      })
    },
    markAsRead(notif) {
      if(!notif.is_seen){
        this.$store.dispatch("notification/markNotifAsRead", notif.id);
      }
      this.$router.push({path: notif.url});
    }
  }
}
</script>

<style type="text/css">
[dir] .vx-card .vx-card__collapsible-content .vx-card__body {
    padding-top: .5rem !important;
}
#table-demo .unread-bg{
  background-color: #d3d3d3;
}
</style>
